import React, {useEffect} from 'react';
import './Facebook.css';

export default function Facebook({netshare}) {

  useEffect(()=>{
    window.FB.XFBML.parse();
  },[]); 

  return (
    <>
        <div className="fb-like" 
            data-href={netshare}
            data-width="500" 
            data-height="50" 
            data-colorscheme="light" 
            data-layout="standard" 
            data-action="like" 
            data-share="true">  
        </div>
    </>
  )
}

