import React, { useState, useEffect, Suspense, lazy  } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import BalladeVertical from './components/ballade/BalladeVertical';
const Page = lazy(()=> import('./components/Page'));
const Galerie = lazy(()=> import('./components/galeries/Galerie'));
const Brasserie = lazy(()=> import('./components/brasserie/Brasserie'));
const History = lazy(()=> import('./components/History')); 
const Ballade = lazy(()=> import('./components/ballade/Ballade'));
const Header = lazy(()=> import('./components/Header'));  
const Footer = lazy(()=> import('./components/Footer'));
const Consent = lazy(()=> import('./components/Consent'));
const Article = lazy(()=> import('./components/article/Article'));
const Protection = lazy(()=> import('./components/Protection'));

function App() {

  const [cnsnt, setCnsnt] = useState(false);

  useEffect(() => {
      let script = document.createElement("script");
      script.setAttribute("crossOrigin","anonymous");
      script.setAttribute("nonce","ffENOPcN");
      script.src = "https://connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v15.0&appId=224223911078300&autoLogAppEvents=1";
      script.type = "text/javascript";
      script.defer = true;
      script.async = true;
      document.body.append(script);
    },[]
  )

  return (
      <>
        <Suspense fallback={<div className='loading'>Chargement...</div>}>
          <Consent cnsnt={cnsnt} setCnsnt={setCnsnt} />     
          <Router>
            <Header />  
              <Routes>
                <Route exact path="/" element={ <Page cnsnt={cnsnt} /> } />
                <Route exact path="/:order/:isUrl" element={ <Article cnsnt={cnsnt} /> } /> 
                <Route exact path="/galerie" element={ <Galerie  cnsnt={cnsnt} /> } />
                <Route exact path="/brasserie" element={ <Brasserie cnsnt={cnsnt} /> } />
                <Route exact path="/uboot" element={ <History cnsnt={cnsnt} /> } />
                <Route exact path="/ballade-de-scrat" element={ <Ballade cnsnt={cnsnt} /> } />
                <Route exact path="/ballade-de-scrat-2" element={ <BalladeVertical cnsnt={cnsnt} /> } />
                <Route exact path="/protection-des-donnees" element={ <Protection /> } />
              </Routes> 
            <Footer />
          </Router>
        </Suspense>        
      </>
  );
}

export default App;
