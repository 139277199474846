import React, {useState, useEffect} from 'react';
import Facebook from './Facebook';
import ReactGA from 'react-ga';

export default function Network({cnsnt}) {

  const [urlToShare, setUrlToShare] = useState();

  useEffect(()=> {
    setUrlToShare(document.querySelector(`meta[property="og:url"]`).content);
  });

  if(cnsnt===true) {
    ReactGA.pageview(urlToShare);
  }

  return (
    <>
      <Facebook netshare={document.querySelector(`meta[property="og:url"]`).content} />
    </>
  )
}