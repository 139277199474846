import React, { useEffect } from 'react';
import { balladeGallerieV } from './balladeGallerieV';
import './Ballade.css';
import Network from '../network/Network';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export default function BalladeVertical() {

    const scratCarouselV = balladeGallerieV;

    const isMeta = [
        {
          type : 'og:url',
          text : 'https://floydinbremen.com/ballade'
        },
        {
          type : 'og:title',
          text : 'Scrat en ballade'
        },
        {
          type : 'og:image',
          text : '/assets/images/scrat/IMG7163.jpg'
        },
        {
          type : 'og:description', 
          text : 'Galerie photo de divers endroits avec la peluche Scrat'
        }
      ]
   
      useEffect(()=> {
          isMeta.map((og) => {
            if(typeof document.querySelector(`meta[property="${og.type}"]`)!=="undefined" 
               && document.querySelector(`meta[property="${og.type}"]`)!==null) {
                document.querySelector(`meta[property="${og.type}"]`).content = og.text;
            } else {
              let insertMeta = document.createElement('meta');
              insertMeta.setAttribute('property',og.type);
              insertMeta.setAttribute('content', og.text);
              document.head.appendChild(insertMeta);
            }
            return null;
          })
        },[]
      )

    return (
        <>
            <h4 className='ballHeader'>Le tour d´ horizon</h4>
            <div className='ballade'>
                <Carousel
                    width="100%"
                    dynamicHeight="true"
                    showThumbs="false">
                        {
                            scratCarouselV.map((item)=>
                            <>
                                <img src={`/assets/images/scrat/${item.src}`} className="imgBallade"/>
                                <p className="legend">{item.desc}</p>
                            </>
                            )
                        }
                </Carousel>
                <Network />
            </div>   
        </>

    );
  }